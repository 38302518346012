<template>
  <div class="container ma-tb-20">
    <car-state></car-state>
    <div class="bg-fff setting-index">
      <back-button msg="历史任务详情"></back-button>
      <div class="flex f-between">
        <div class="box bg-F7">
          <div class="box-title fon-18 font-bold">病人信息</div>
          <div class="fon-16 box-list">
            <div class="flex f-center ma-5">
              <div class="c-999">报警时间:</div>
              <div class="c-000">{{ detail.TaskInfo.AlarmTime }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">报警人:</div>
              <div class="c-000">
                {{ detail.TaskInfo.People }} - {{ detail.TaskInfo.Phone }}
              </div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">病人:</div>
              <div class="c-000">
                {{ detail.TaskInfo.PatientName }}({{
                  detail.TaskInfo.PatientGender
                }}) {{ detail.TaskInfo.PatientAge }}岁
              </div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">担架:</div>
              <div class="c-000">{{ IsStretcher ? "需要" : "不需要" }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">事故地点:</div>
              <div class="c-000">{{ detail.TaskInfo.SceneAddress }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">起因/症状:</div>
              <div class="c-000">{{ detail.TaskInfo.Symptom }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">受伤部位:</div>
              <div class="c-000">{{ detail.TaskInfo.InjuredPart }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">当前状态:</div>
              <div class="c-000">{{ detail.TaskInfo.Status }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">过敏源:</div>
              <div class="c-000">{{ detail.TaskInfo.Allergen }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">病史:</div>
              <div class="c-000">{{ detail.TaskInfo.MedicalHistory }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">说明:</div>
              <div class="c-000">{{ detail.TaskInfo.Remark }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">急救站:</div>
              <div class="c-000">{{ detail.TaskInfo.StationName }}</div>
            </div>
            <div class="flex f-center ma-5">
              <div class="c-999">急救医院:</div>
              <div class="c-000">{{ detail.TaskInfo.HospitalName }}</div>
            </div>
            <router-link :to="{ name: 'taskCaseView', query: { id: taskId } }">
              <div class="btn-add c-fff fon-16 flex f-center f-jcenter">
                查看电子病历
              </div>
            </router-link>
          </div>
        </div>
        <div class="box flex f-between flex-direction f-between">
          <div class="bg-F7 box-log">
            <div class="box-title fon-18 font-bold">现场医嘱</div>
            <div class="log-list">
              <div v-if="detail.MedicalAdvice.length > 0">
                <div
                  class="ma-5"
                  v-for="(item, index) in detail.MedicalAdvice"
                  :key="index"
                >
                  <div class="flex f-center f-between">
                    <div class="time fon-16">{{ item.InsertTime }}</div>
                  </div>
                  <div class="fon-16 font-bold c-666">{{ item.Content }}</div>
                </div>
              </div>
              <van-empty image-size="40" description="暂无医嘱记录" v-else />
            </div>
          </div>
          <div class="bg-F7 box-log">
            <div class="box-title fon-18 font-bold">人员信息</div>
            <div class="log-list">
              <div class="ma-5 c-666 fon-16">
                {{ detail.Member.Driver.Name }}(司机)
              </div>
              <div class="ma-5 c-666 fon-16">
                {{ detail.Member.Doctor.Name }}(医生)
              </div>
              <div class="ma-5 c-666 fon-16">
                {{ detail.Member.Nurse.Name }}(护士)
              </div>
              <div class="ma-5 c-666 fon-16">
                {{ detail.Member.Stretcher.Name }}(担架员)
              </div>
            </div>
          </div>
        </div>
        <div class="box flex f-between flex-direction f-between">
          <div class="bg-F7 box-log">
            <div class="box-title fon-18 font-bold">系统日志</div>
            <div class="log-list" v-if="detail.LogList.length > 0">
              <div
                class="ma-5 c-666 fon-16"
                v-for="(item, index) in detail.LogList"
                :key="index"
              >
                {{ item.InsertTime }}&nbsp;&nbsp;{{ item.Content }}
              </div>
            </div>
            <van-empty image-size="40" description="暂无系统日志" v-else />
          </div>
          <div class="map-box">
            <div id="container" style="width: 100%; height: 220px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let _this;
import AMap from "AMap"; // 引入高德地图
export default {
  data() {
    return {
      detail: {
        TaskInfo: "",
        MedicalAdvice: [],
        Member: {
          Driver: "",
          Doctor: "",
          Nurse: "",
          Stretcher: "",
        },
        LogList: [],
      },
      taskId: "",
      PolylinePath: [],
    };
  },
  created() {
    _this = this;
    _this.taskId = _this.$route.query.id;
    _this.loadData();
  },
  mounted() {
    _this.init();
  },
  methods: {
    /**
     * 获取救护车的位置
     */
    async init() {
      _this.mapList = await _this.$request(
        "/Ambulance/v1/Location/Get?taskId=" + _this.taskId,
        {},
        false,
        true
      );
      _this.mapList = _this.mapList.Data;
      if (_this.mapList.PolylinePath.length > 0) {
        _this.mapList.PolylinePath.forEach((e, v) => {
          _this.mapList.PolylinePath[v] = JSON.parse(e);
        });
        _this.centerLon =
          _this.mapList.PolylinePath[_this.mapList.PolylinePath.length - 1][0];
        _this.centerLat =
          _this.mapList.PolylinePath[_this.mapList.PolylinePath.length - 1][1];
      } else {
        _this.centerLon = _this.mapList.Hospital.Longitude;
        _this.centerLat = _this.mapList.Hospital.Latitude;
        clearInterval(_this.loadDataIntervala);
      }

      _this.getMap();
      _this.once = 1;
    },

    getMap() {
      if (!_this.once) {
        _this.map = new AMap.Map("container", {
          resizeEnable: true,
          expandZoomRange: true,
          center: [_this.centerLon, _this.centerLat], //地图中心点
          zoom: 16,
        });
      } 
      // 创建一个 Marker 实例：

       if (_this.mapList.PolylinePath.length > 0) {
        AMap.convertFrom(
          _this.mapList.PolylinePath,
          "gps",
          function (status, result) {
            if (result.info === "ok") {
			
              _this.map.setZoomAndCenter(_this.map.getZoom(), [
                result.locations[result.locations.length - 1].KL,
                result.locations[result.locations.length - 1].kT,
              ]);
               _this.PolylinePath = [];
              result.locations.forEach((v, e) => {
                _this.PolylinePath.push([v.KL, v.kT]);
              });
              console.log(_this.PolylinePath);
              _this.path = new AMap.Polyline({
                path: _this.PolylinePath,
                isOutline: false, //线条是否带描边，默认false
                outlineColor: "#ffeeff", //线条描边颜色，此项仅在isOutline为true时有效，默认：#000000
                borderWeight: 1, //描边的宽度，默认为1
                strokeColor: "#3366FF", //线条颜色，使用16进制颜色代码赋值。默认值为#006600
                strokeOpacity: 1, //线条透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
                strokeWeight: 3, //线条宽度，单位：像素
                // 折线样式还支持 'dashed'
                strokeStyle: "solid", //线样式，实线:solid，虚线:dashed
                // strokeStyle是dashed时有效
                // strokeDasharray: [10, 5],//勾勒形状轮廓的虚线和间隙的样式，此属性在strokeStyle 为dashed 时有效
                lineJoin: "round", //折线拐点的绘制样式，默认值为'miter'尖角，其他可选值：'round'圆角、'bevel'斜角
                lineCap: "round", //折线两端线帽的绘制样式，默认值为'butt'无头，其他可选值：'round'圆头、'square'方头
                zIndex: 50, //折线覆盖物的叠加顺序。默认叠加顺序，先添加的线在底层，后添加的线在上层。通过该属性可调整叠加顺序，使级别较高的折线覆盖物在上层显示默认zIndex：50、
              });
              // // 将折线添加至地图实例
              _this.map.add(_this.path);
            }
          }
        );
      } else {
        AMap.convertFrom(
          [_this.centerLon, _this.centerLat],
          "gps",
          function (status, result) {
            if (result.info === "ok") {
              _this.map.setZoomAndCenter(_this.map.getZoom(), [
                result.locations[0].KL,
                result.locations[0].kT,
              ]);
            }
          }
        );
      }
      //医院的位置
      //  _this.marker3 = new AMap.Marker({
      //   position: new AMap.LngLat(
      //     _this.mapList.Hospital.Longitude,
      //     _this.ma  ), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      //   title: _this.mapList.Hospital.Name,
      // });pList.Hospital.Latitude
      //

      //救护车的位置

      // _this.marker4 = new AMap.Marker({
      //     position: new AMap.LngLat(
      //       _this.mapList.PolylinePath[_this.mapList.PolylinePath.length - 1][0],
      //       _this.mapList.PolylinePath[_this.mapList.PolylinePath.length - 1][1]
      //     ), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      //     title: "救护车的位置",
      // icon:'',
      // icon: new AMap.Icon({
      //   image:
      //     "https://weiting-develop.oss-cn-shanghai.aliyuncs.com/Hospital/Image/390f1aa956b04f7e85f921429e1946d4_2021.png",
      //   size: new AMap.Size(28, 28), //图标所处区域大小
      //   imageSize: new AMap.Size(28, 28), //图标大小
      // }),
      // });
      // 将创建的点标记添加到已有的地图实例：
      // _this.map.add( _this.marker3);
      // _this.map.add( _this.marker4);
    },
    // 加载数据
    async loadData() {
      let _data = await _this.$request(
        "/Task/v1/TaskTask/Detail?taskId=" + _this.taskId
      );
      if (_data.Code == 0) {
        _this.detail = _data.Data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  margin-left: 0;
}
.ma-5 {
  margin: 5px 5px 5px 9px;
}
.bg-F7 {
  background: #f7f7f7;
}
.setting-index {
  height: auto;
  .box {
    min-height: 460px;
    width: 32%;
    border-radius: 4px;
    position: relative;
    margin-top: 8px;
    .c-999 {
      width: 38%;
    }
    .c-000 {
      width: 90%;
    }
    .box-list {
      height: 385px;
      overflow-y: scroll;
    }
    .box-title {
      color: #5cb9da;
      padding: 5px 9px;
      border-bottom: 1px solid #5ab8d9;
    }
    .btn-add {
      width: 100%;
      height: 33px;
      background: #5cb9da;
      border-radius: 4px;
      position: absolute;
      bottom: 0;
    }
    .time {
      color: #a0a0a0;
    }
    .btn-detele {
      font-size: 10px;
      font-family: MicrosoftYaHei;
      color: #989696;
      line-height: 16px;
      background: #e6e6e6;
      width: 35px;
      height: 20px;
      border-radius: 5px;
    }
    .box-log {
      height: 220px;
      border-radius: 4px;
      .log-list {
        height: 140px;
        overflow-y: scroll;
      }
    }
    .map-box {
      width: 100%;
      height: 220px;
    }
  }
}
</style>